import _ from "lodash";
import { get } from "lodash";

import authdAxios from "../modules/Authentication/utils/authdAxios";
import config from "../config";
import constants from "../constants";
import { onThen, onCatch } from "./utils";
import axios from "axios";
//import fs from "file-saver";
const CompanyService = () => {
  function getCompanies() {
    return new Promise((resolve, reject) => {
      authdAxios
        .post(
          `${config.COMPANY_API}/api/company/get-all`,
          {},
          {
            headers: {
              "content-type": "application/json",
            },
          }
        )
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(
            get(
              error,
              "response.data.payload",
              constants.ERROR_MESSAGES.DEFAULT
            )
          );
        });
    });
  }
  //start
  function getRepList() {
    return new Promise((resolve, reject) => {
      authdAxios
        .post(
          `${config.CUSTOMER_API}/api/reps/rep-type-list`,
          {},
          {
            headers: {
              "content-type": "application/json",
            },
          }
        )
        .then((response) => {
          console.log("response rep list-->>>>",response)
          resolve(response.data);
        })
        .catch((error) => {
          reject(
            get(
              error,
              "response.data.payload",
              constants.ERROR_MESSAGES.DEFAULT
            )
          );
        });
    });
  }

  function getRepAttrList(companyId) {
    // console.log("getRepAttrList companyId1-->>", companyId);
    // companyId: companyId
    return new Promise((resolve, reject) => {
      authdAxios
        .post(
          `${config.CUSTOMER_API}/api/reps/get-rep-type-list`,
          { companyId },
          {
            headers: {
              "content-type": "application/json",
            },
          }
        )
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(
            get(
              error,
              "response.data.payload",
              constants.ERROR_MESSAGES.DEFAULT
            )
          );
        });
    });
  }

  function getCompanyInfo(id) {
    return new Promise((resolve, reject) => {
      authdAxios
        .post(
          `${config.COMPANY_API}/api/company/get-info`,
          {
            companyId: id,
          },
          {
            timeout: config.API_TIMEOUT_DURATION,
            headers: {
              "content-type": "application/json",
            },
          }
        )
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(
            get(
              error,
              "response.data.payload",
              constants.ERROR_MESSAGES.DEFAULT
            )
          );
        });
    });
  }

  function getCompanyLogo(url) {
    // https://dev.repora.co:4032/api/company/get-Company-logo?imageUrl=https://s3.us-west-2.amazonaws.com/repora-company-logo/1636996121812-transparent_ball.png
    return new Promise((resolve, reject) => {
      authdAxios
        .get(
          `${config.COMPANY_API}/api/company/get-Company-logo?imageUrl=${url}`,
          {
            headers: {
              // 'Content-Disposition': "attachment; filename=template.xlsx",
              // 'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            },
            responseType: "arraybuffer",
          }
        )
        .then((response) => {
          resolve(response.data);
          const url = window.URL.createObjectURL(new Blob([response.data]));
          resolve(url);
          // const link = document.createElement('a');
          // link.href = url;
          // link.setAttribute('download', data.split("repora-crm-files/")[1]);
          // document.body.appendChild(link);
          // link.click();
        })
        .catch((error) => {
          reject(
            get(
              error,
              "response.data.payload",
              constants.ERROR_MESSAGES.DEFAULT
            )
          );
        });
    });
  }

  function getCompanyRepList(
    companyId,
    beginDateInMillis,
    endDateInMillis,
    verifiedCustomerOnly,
    anonymousHidden,
    tagId,
    role,
    emailId,
    myRole,
    seeAllData
  ) {
    let obj = {
      companyId,
      beginDateInMillis,
      endDateInMillis,
      verifiedCustomerOnly,
      anonymousHidden,
      repType: role,
      tagId,
    };
    if (myRole === "REGULAR" && !seeAllData) {
      Object.assign(obj, { companyUserEmail: emailId });
    }
    return new Promise((resolve, reject) => {
      authdAxios
        .post(`${config.REP_API}/api/company-dashboard/top-reps`, obj, {
          timeout: config.API_TIMEOUT_DURATION,
          headers: {
            "content-type": "application/json",
          },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(
            get(
              error,
              "response.data.payload",
              constants.ERROR_MESSAGES.DEFAULT
            )
          );
        });
    });
  }

  function updateCompanyInfo(id, info) {
    return new Promise((resolve, reject) => {
      authdAxios
        .post(
          `${config.COMPANY_API}/api/company/update-info`,
          {
            id: id,
            name: info.name,
            industry: info.industry,
            linkedinUrl: "",
            privateCompany: info.privateCompany,
            physicalAddress: {
              country: info.country,
              city: info.city,
              state: info.state,
              zipCode: info.zipcode,
            },
          },
          {
            headers: {
              "content-type": "application/json",
            },
          }
        )
        .then(({ data }) => {
          // debugger;
          if (data.statusCode !== 200) {
            reject(data.payload || constants.ERROR_MESSAGES.DEFAULT);
          }
          resolve(data);
        })
        .catch((error) => {
          // debugger;
          const { data } = error.response || { data: {} };
          try {
            if (
              error.data.payload ===
              "That company name already exists. Please select a different name."
            ) {
              reject(error.data.payload);
            } else {
              reject(data.payload || constants.ERROR_MESSAGES.DEFAULT);
            }
          } catch (e) {
            reject(data.payload || constants.ERROR_MESSAGES.DEFAULT);
          }
        });
    });
  }

  function getUserCompanies(userId) {
    console.log("userId-->>>",userId)
    let url = `${config.COMPANY_API}/company-users/list-company-user`;
    url = url.replace("4032", "3032");
    return new Promise((resolve, reject) => {
      axios
        .post(
          url,
          { userId: userId },
          {
            timeout: config.API_TIMEOUT_DURATION,
            headers: {
              "content-type": "application/json",
            },
          }
        )
        .then((response) => {
          console.log("response-->>>>",response)
          resolve(response.data.payload);
        })
        .catch((error) => {
          reject(
            get(
              error,
              "response.data.payload",
              constants.ERROR_MESSAGES.DEFAULT
            )
          );
        });
    });
  }

  function getUserCompaniesSample(userId) {
    let url = `${config.COMPANY_API}/company-users/list-company-user-sample`;
    url = url.replace("4032", "3032");
    return new Promise((resolve, reject) => {
      axios
        .post(
          url,
          { userId: userId },
          {
            timeout: config.API_TIMEOUT_DURATION,
            headers: {
              "content-type": "application/json",
            },
          }
        )
        .then((response) => {
          resolve(response.data.payload);
        })
        .catch((error) => {
          reject(
            get(
              error,
              "response.data.payload",
              constants.ERROR_MESSAGES.DEFAULT
            )
          );
        });
    });
  }

  function _addUserCompany(company) {
    return new Promise((resolve, reject) => {
      authdAxios
        .post(
          `${config.COMPANY_API}/api/company-users/add-company-user`,
          [
            {
              userId: company.userId,
              name: company.name,
              industry: company.industry,
              companyLinkedinId: null,
              current: company.current || false,
              startDate: company.startDate,
              endDate: company.endDate,
              productSpeciality: company.productSpeciality,
              enrolled: company.enrolled || false,
              repType: company.role || null,
              physicalAddress: {
                country: company.country,
                city: company.city,
                state: company.state,
                zipCode: company.zipCode,
                phoneNumber: null,
                territory: company.territory,
              },
            },
          ],
          {
            timeout: config.API_TIMEOUT_DURATION,
            headers: {
              "content-type": "application/json",
            },
          }
        )
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(get(error, "data.payload", constants.ERROR_MESSAGES.DEFAULT));
        });
    });
  }

  function addUserCompany(company, userLogged) {
    if (userLogged) {
      return _addUserCompany(company);
    } else {
      let url = `${config.COMPANY_API}/api/link/add-company-user`;
      url = url.replace("4032", "3032");
      return new Promise((resolve, reject) => {
        axios
          .post(
            url,
            [
              {
                userId: company.userId,
                name: company.name,
                companyLinkedinId: null,
                current: false,
                enrolled: false,
                physicalAddress: {
                  phoneNumber: null,
                },
              },
            ],
            {
              timeout: config.API_TIMEOUT_DURATION,
              headers: {
                "content-type": "application/json",
              },
            }
          )
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            reject(
              get(error, "data.payload", constants.ERROR_MESSAGES.DEFAULT)
            );
          });
      });
    }
  }

  function addUserCompany(company, userLogged) {
    if (userLogged) {
      return _addUserCompany(company);
    } else {
      let url = `${config.COMPANY_API}/api/link/add-company-user`;
      return new Promise((resolve, reject) => {
        axios
          .post(
            url,
            [
              {
                userId: company.userId,
                name: company.name,

                companyLinkedinId: null,
                current: false,
                enrolled: false,
                physicalAddress: {
                  phoneNumber: null,
                },
              },
            ],
            {
              timeout: config.API_TIMEOUT_DURATION,
              headers: {
                "content-type": "application/json",
              },
            }
          )
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            reject(
              get(error, "data.payload", constants.ERROR_MESSAGES.DEFAULT)
            );
          });
      });
    }
  }

  function updateUserCompany(company) {
    return new Promise((resolve, reject) => {
      authdAxios
        .post(
          `${config.COMPANY_API}/api/company-users/update-company-user`,
          {
            companyId: company.companyId,
            userId: company.userId,
            name: company.name,
            industry: company.industry,
            companyLinkedinId: null,
            current: company.current,
            startDate: company.startDate,
            endDate: company.endDate,
            productSpeciality: company.productSpeciality,
            physicalAddress: {
              country: company.country,
              city: company.city,
              state: company.state,
              zipCode: company.zipCode,
              phoneNumber: null,
              territory: company.territory,
            },
            enrolled: company.enrolled,
            editableByLinkedin: company.editableByLinkedin,
            connected: company.connected,
            repType: company.role,
          },
          {
            headers: {
              "content-type": "application/json",
            },
          }
        )
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(get(error, "data.payload", constants.ERROR_MESSAGES.DEFAULT));
        });
    });
  }

  function deleteUserCompany(companyId, userId) {
    return new Promise((resolve, reject) => {
      authdAxios
        .post(
          `${config.COMPANY_API}/api/company-users/delete-company-user`,
          {
            companyId: companyId,
            userId: userId,
          },
          {
            timeout: config.API_TIMEOUT_DURATION,
            headers: {
              "content-type": "application/json",
            },
          }
        )
        .then((response) => {
          resolve(response.data.payload);
        })
        .catch((error) => {
          reject(
            get(
              error,
              "response.data.payload",
              constants.ERROR_MESSAGES.DEFAULT
            )
          );
        });
    });
  }

  function getPreviousCompanies(repId) {
    return new Promise((resolve, reject) => {
      authdAxios
        .post(
          `${config.COMPANY_API}/api/company-users/list-company-user`,
          {
            userId: repId,
          },
          {
            headers: {
              "content-type": "application/json",
            },
          }
        )
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(
            get(
              error,
              "response.data.payload",
              constants.ERROR_MESSAGES.DEFAULT
            )
          );
        });
    });
  }

  function getMapsData(
    companyId,
    beginDateInMillis,
    endDateInMillis,
    verifiedCustomerOnly,
    anonymousHidden
  ) {
    return new Promise((resolve, reject) => {
      authdAxios
        .post(
          `${config.REP_API}/api/company-dashboard/maps`,
          {
            companyId: companyId,
            beginDateInMillis: beginDateInMillis,
            endDateInMillis: endDateInMillis,
            verifiedCustomerOnly: verifiedCustomerOnly,
            anonymousHidden: anonymousHidden,
          },
          {
            timeout: config.API_TIMEOUT_DURATION,
            headers: {
              "content-type": "application/json",
            },
          }
        )
        .then((response) => {
          resolve(response.data.payload);
        })
        .catch((error) => {
          reject(
            get(
              error,
              "response.data.payload",
              constants.ERROR_MESSAGES.DEFAULT
            )
          );
        });
    });
  }

  function getDashboardCompanyInfo(
    companyId,
    verifiedCustomerOnly,
    anonymousHidden,
    tagId,
    role,
    emailId,
    myRole,
    seeAllData
  ) {
    let obj = {
      companyId: companyId,
      beginDateInMillis: "0",
      endDateInMillis: "1543968000",
      verifiedCustomerOnly,
      anonymousHidden,
      repType: role,
      tagId,
    };
    if (myRole === "REGULAR" && !seeAllData) {
      Object.assign(obj, { companyUserEmail: emailId });
    }
    return new Promise((resolve, reject) => {
      authdAxios
        .post(
          `${config.REP_API}/api/company-dashboard/company-info-sample`,
          obj,
          {
            timeout: config.API_TIMEOUT_DURATION,
            headers: {
              "content-type": "application/json",
            },
          }
        )
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(
            get(
              error,
              "response.data.payload",
              constants.ERROR_MESSAGES.DEFAULT
            )
          );
        });
    });
  }

  function getHotspotReport(
    companyId,
    beginDateInMillis,
    endDateInMillis,
    verifiedCustomerOnly,
    anonymousHidden,
    tagId,
    role,
    emailId,
    myRole,
    seeAllData
  ) {
    let obj = {
      companyId,
      beginDateInMillis,
      endDateInMillis,
      verifiedCustomerOnly,
      anonymousHidden,
      tagId,
      repType: role,
    };
    if (myRole === "REGULAR" && !seeAllData) {
      Object.assign(obj, { companyUserEmail: emailId });
    }
    return new Promise((resolve, reject) => {
      authdAxios
        .post(`${config.REP_API}/api/company-dashboard/hotspot`, obj, {
          timeout: config.API_TIMEOUT_DURATION,
          headers: {
            "content-type": "application/json",
          },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(
            get(
              error,
              "response.data.payload",
              constants.ERROR_MESSAGES.DEFAULT
            )
          );
        });
    });
  }

  function updateLogo(companyId, logo) {
    let formdata = new FormData();
    formdata.append("logo", logo);
    formdata.append("companyId", companyId);
    return new Promise((resolve, reject) => {
      authdAxios
        .post(
          `${config.COMPANY_API}/api/company/update-Company-logo`,
          formdata,
          {
            headers: {
              "content-type": "application/json",
            },
          }
        )
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(get(error, "data.payload", constants.ERROR_MESSAGES.DEFAULT));
        });
    });
  }

  function deleteLogo(companyId) {
    return new Promise((resolve, reject) => {
      authdAxios
        .post(
          `${config.COMPANY_API}/api/company/delete-logo`,
          {
            companyId,
          },
          {
            headers: {
              "content-type": "application/json",
            },
          }
        )
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(get(error, "data.payload", constants.ERROR_MESSAGES.DEFAULT));
        });
    });
  }

  function setMinimumRating(companyId, minimumRatings) {
    return new Promise((resolve, reject) => {
      authdAxios
        .post(
          `${config.COMPANY_API}/api/company/reporting/minimum-ratings`,
          {
            companyId,
            minimumRatings,
          },
          {
            headers: {
              "content-type": "application/json",
            },
          }
        )
        .then(({ data }) => {
          if (data.statusCode !== 200) {
            reject(data.payload || constants.ERROR_MESSAGES.DEFAULT);
          }
          resolve(data);
        })
        .catch((error) => {
          const { data } = error.response || { data: {} };
          reject(data.payload || constants.ERROR_MESSAGES.DEFAULT);
        });
    });
  }

  function getCompanyAverageRatings(
    companyId,
    verifiedCustomerOnly,
    anonymousHidden,
    compared,
    tagId,
    role,
    emailId,
    myRole,
    seeAllData
  ) {
    let obj = {
      companyId,
      verifiedCustomerOnly,
      anonymousHidden,
      compared,
      repType: role,
      tagId,
    };

    if (myRole === "REGULAR" && !seeAllData) {
      Object.assign(obj, { companyUserEmail: emailId });
    }
    return new Promise((resolve, reject) => {
      authdAxios
        .post(`${config.REP_API}/api/company-dashboard/company-averages`, obj, {
          timeout: config.API_TIMEOUT_DURATION,
          headers: {
            "content-type": "application/json",
          },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(
            get(
              error,
              "response.data.payload",
              constants.ERROR_MESSAGES.DEFAULT
            )
          );
        });
    });
  }

  function getCompanyRepTrends(
    companyId,
    beginDateInMillis,
    endDateInMillis,
    verifiedCustomerOnly,
    anonymousHidden,
    tagId,
    role,
    emailId,
    myRole,
    seeAllData
  ) {
    let obj = {
      companyId,
      beginDateInMillis,
      endDateInMillis,
      verifiedCustomerOnly,
      anonymousHidden,
      repType: role,
      tagId,
    };
    if (myRole === "REGULAR" && !seeAllData) {
      Object.assign(obj, { companyUserEmail: emailId });
    }
    return new Promise((resolve, reject) => {
      authdAxios
        .post(`${config.REP_API}/api/company-dashboard/graphycs`, obj, {
          timeout: config.API_TIMEOUT_DURATION,
          headers: {
            "content-type": "application/json",
          },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(
            get(
              error,
              "response.data.payload",
              constants.ERROR_MESSAGES.DEFAULT
            )
          );
        });
    });
  }

  function getCompanyMaps(
    companyId,
    beginDateInMillis,
    endDateInMillis,
    verifiedCustomerOnly,
    anonymousHidden,
    category,
    filter,
    value,
    threshold,
    repType,
    tagId,
    emailId,
    myRole,
    seeAllData
  ) {
    let obj = {
      companyId,
      beginDateInMillis,
      endDateInMillis,
      verifiedCustomerOnly,
      anonymousHidden,
      category,
      filter,
      value,
      threshold,
      repType,
      tagId,
    };
    if (myRole === "REGULAR" && !seeAllData) {
      Object.assign(obj, { companyUserEmail: emailId });
    }
    return new Promise((resolve, reject) => {
      authdAxios
        .post(`${config.REP_API}/api/company-dashboard/maps`, obj, {
          timeout: config.API_TIMEOUT_DURATION,
          headers: {
            "content-type": "application/json",
          },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(
            get(
              error,
              "response.data.payload",
              constants.ERROR_MESSAGES.DEFAULT
            )
          );
        });
    });
  }

  function reportAbuse(payload) {
    const {
      notifyMe,
      message,
      comment,
      nameRep,
      emailRep,
      nameReporter,
      emailReporter,
      role,
      customerName,
      repComapny,
      customerComapny,
      overall_rating,
      customercomment,
      overAllRating,
      reviewDate,
      reviewId,
      customRatingCategory,
    } = payload;

    return new Promise((resolve, reject) => {
      authdAxios
        .post(
          `${config.NOTIFICATION_API}/api/notification/rep/report-abuse`,
          {
            notifyMe: notifyMe,
            message: message,
            comment: comment,
            nameRep: nameRep,
            emailRep: emailRep,
            nameReporter: nameReporter,
            emailReporter: emailReporter,
            role: role,
            customerName: customerName,
            repCompany: repComapny,
            customerCompany: customerComapny,
            ratingPropertise: overall_rating,
            customerComment: customercomment,
            overAllRating: overAllRating,
            reviewDate: reviewDate,
            reviewId: reviewId,
            customRatingCategory: customRatingCategory,
          },
          {
            headers: {
              "content-type": "application/json",
            },
          }
        )
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(
            get(
              error,
              "response.data.payload",
              constants.ERROR_MESSAGES.DEFAULT
            )
          );
        });
    });
  }

  function getCompanyPublicInfo(companyId) {
    return new Promise((resolve, reject) => {
      authdAxios
        .post(
          `${config.COMPANY_API}/api/company/invited-info`,
          { companyId },
          {
            timeout: config.API_TIMEOUT_DURATION,
            headers: {
              "content-type": "application/json",
            },
          }
        )
        .then((response) => {
          resolve(response.data.payload);
        })
        .catch((error) => {
          reject(get(error, "data.payload", constants.ERROR_MESSAGES.DEFAULT));
        });
    });
  }

  function getCompanyPublicInfoByInvite(inviteId) {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${config.COMPANY_API}/api/company/invited-rep-info`,
          { inviteId },
          {
            timeout: config.API_TIMEOUT_DURATION,
            headers: {
              "content-type": "application/json",
            },
          }
        )
        .then((response) => {
          resolve(response.data.payload);
        })
        .catch((error) => {
          reject(get(error, "data.payload", constants.ERROR_MESSAGES.DEFAULT));
        });
    });
  }

  function getAssignedRoles(companyId) {
    return new Promise((resolve, reject) => {
      authdAxios
        .post(
          `${config.CUSTOMER_API}/api/manage-reps/assigned-roles`,
          {
            companyId,
          },
          {
            timeout: config.API_TIMEOUT_DURATION,
            headers: {
              "content-type": "application/json",
            },
          }
        )
        .then(_.partial(onThen, resolve, reject))
        .catch(_.partial(onCatch, reject));
    });
  }

  function addCompany(company) {
    return new Promise((resolve, reject) => {
      authdAxios
        .post(`${config.COMPANY_API}/api/company/add`, company, {
          headers: {
            "content-type": "application/json",
          },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(
            get(
              error,
              "response.data.payload",
              constants.ERROR_MESSAGES.DEFAULT
            )
          );
        });
    });
  }

  function getCompanySuggestions(companyName, repId) {
    let obj = { companyName: companyName, repId: repId };
    if (repId === "REPORA_ADMIN") {
      obj = { companyName: companyName, userRole: repId };
    }
    return new Promise((resolve, reject) => {
      authdAxios
        .post(
          `${config.COMPANY_API}/api/company/get-companies-suggestions`,
          obj,
          {
            timeout: config.API_TIMEOUT_DURATION,
            headers: {
              "content-type": "application/json",
            },
          }
        )
        .then((response) => {
          resolve(response.data.payload);
        })
        .catch((error) => {
          reject(get(error, "data.payload", constants.ERROR_MESSAGES.DEFAULT));
        });
    });
  }

  function addCustomeAttributes(attrData) {
    return new Promise((resolve, reject) => {
      authdAxios
        .post(`${config.REP_API}/api/custom/add-custom-attribute`, attrData, {
          headers: {
            "content-type": "application/json",
          },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  function getCompanyCustomeAttr(obj) {
    let URL = `${config.REP_API}/api/custom/get-custom-attribute-list-company`;
    if (obj.companyFeedbackCategory && obj.companyFeedbackCategory != "REPS") {
      URL = `${config.REP_API}/api/company-feedback/get-custom-cfc-attribute-list-by-company`;
    }
    return new Promise((resolve, reject) => {
      axios
        .post(URL, obj, {
          headers: {
            "content-type": "application/json",
            Authorization: "",
          },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(
            get(
              error,
              "response.data.payload",
              constants.ERROR_MESSAGES.DEFAULT
            )
          );
        });
    });
  }

  function getCompanyCustomeAttrSample(obj) {
    let URL = `${config.REP_API}/api/custom/get-custom-attribute-list-company-sample`;
    if (obj.companyFeedbackCategory && obj.companyFeedbackCategory != "REPS") {
      URL = `${config.REP_API}/api/company-feedback/get-custom-cfc-attribute-list-by-company`;
    }
    return new Promise((resolve, reject) => {
      axios
        .post(URL, obj, {
          headers: {
            "content-type": "application/json",
            Authorization: "",
          },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(
            get(
              error,
              "response.data.payload",
              constants.ERROR_MESSAGES.DEFAULT
            )
          );
        });
    });
  }

  function updateCustomeAttributes(attrData) {
    let URL = `${config.REP_API}/api/custom/update-custom-attribute`;
    if (
      attrData.companyFeedbackCategory &&
      attrData.companyFeedbackCategory != "REPS"
    ) {
      URL = `${config.REP_API}/api/company-feedback/update-custom-cfc-attribute`;
    }
    return new Promise((resolve, reject) => {
      authdAxios
        .post(URL, attrData, {
          headers: {
            "content-type": "application/json",
          },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  function deleteCustomeAttributes(attrData) {
    let URL = `${config.REP_API}/api/custom/delete-custom-attribute`;
    if (
      attrData.companyFeedbackCategory &&
      attrData.companyFeedbackCategory != "REPS"
    ) {
      URL = `${config.REP_API}/api/company-feedback/delete-custom-cfc-attribute`;
    }
    return new Promise((resolve, reject) => {
      authdAxios
        .post(
          URL,
          { id: attrData.id },
          {
            headers: {
              "content-type": "application/json",
            },
          }
        )
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  function uploadCRMExcel(companyId, file, activationDate, name) {
    let formdata = new FormData();
    formdata.append("file", file);
    formdata.append("companyId", companyId);
    formdata.append("activationDate", activationDate.split("-").join("/"));
    formdata.append("fileName", name);
    return new Promise((resolve, reject) => {
      authdAxios
        .post(`${config.PASSPORT_API}/import/import-crm-user-excel`, formdata, {
          headers: {
            "content-type": "application/json",
          },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error.payload);
        });
    });
  }

  function addCRMExcel(data) {
    let url = `${config.PASSPORT_API}/import/add-crm-user`;
    url = url.replace("4031", "3031");
    return new Promise((resolve, reject) => {
      authdAxios
        .post(url, data, {
          headers: {
            "content-type": "application/json",
          },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(get(error, "data.payload", constants.ERROR_MESSAGES.DEFAULT));
        });
    });
  }

  function updateCRMExcel(data) {
    let url = `${config.PASSPORT_API}/import/add-crm-user`;
    url = url.replace("4031", "3031");
    return new Promise((resolve, reject) => {
      authdAxios
        .post(url, data, {
          headers: {
            "content-type": "application/json",
          },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(get(error, "data.payload", constants.ERROR_MESSAGES.DEFAULT));
        });
    });
  }

  function validateCRMData(data) {
    return new Promise((resolve, reject) => {
      authdAxios
        .post(
          `${config.PASSPORT_API}/import/store-crm-users-and-send-invites`,
          data,
          {
            headers: {
              "content-type": "application/json",
            },
          }
        )
        .then((response) => {
          if (response.payload) {
            resolve(response.payload);
          }
        })
        .catch((error) => {
          reject(get(error, "data.payload", constants.ERROR_MESSAGES.DEFAULT));
        });
    });
  }

  function updatevalidateCRMData(data) {
    return new Promise((resolve, reject) => {
      authdAxios
        .post(
          `${config.PASSPORT_API}/import/update-temp-crm-users-by-list`,
          data,
          {
            headers: {
              "content-type": "application/json",
            },
          }
        )
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(get(error, "data.payload", constants.ERROR_MESSAGES.DEFAULT));
        });
    });
  }

  function deleteCRMData(data) {
    return new Promise((resolve, reject) => {
      authdAxios
        .post(`${config.PASSPORT_API}/import/del-temp-crm-user-company`, data, {
          headers: { "content-type": "application/json" },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(get(error, "data.payload", constants.ERROR_MESSAGES.DEFAULT));
        });
    });
  }

  function getCRMFileData(data) {
    return new Promise((resolve, reject) => {
      authdAxios
        .post(
          `${config.PASSPORT_API}/import/get-crm-hierarchy-files-company`,
          data,
          {
            headers: { "content-type": "application/json" },
          }
        )
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(get(error, "data.payload", constants.ERROR_MESSAGES.DEFAULT));
        });
    });
  }

  function getCRMFileDownload(data, fileName) {
    return new Promise((resolve, reject) => {
      authdAxios
        .get(
          `${config.PASSPORT_API}/import/get-crm-hierarchy-file?fileUrl=${data}`,
          {
            headers: {
              // 'Content-Disposition': "attachment; filename=template.xlsx",
              // 'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            },
            responseType: "arraybuffer",
          }
        )
        .then((response) => {
          // const url = window.URL.createObjectURL(new File([new Blob([response.data])], "LOR.xlsx", { type: 'application/xlsx' }))
          // const link = document.createElement('a');
          // link.href = url;
          // link.setAttribute('download', data.split("repora-crm-files/")[1]);
          // document.body.appendChild(link);
          // link.click();
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", fileName);
          document.body.appendChild(link);
          link.click();
        })
        .catch((error) => {
          reject(get(error, "data.payload", constants.ERROR_MESSAGES.DEFAULT));
        });
    });
  }

  // https://apidev.cypressdigitalmedia.com:4031/import/get-crm-users-company
  function getCRMusersCompany(data) {
    return new Promise((resolve, reject) => {
      authdAxios
        .post(
          // `${config.PASSPORT_API}/import/get-crm-users-company`,
          // `${config.PASSPORT_API}/api/users/get-user-list-company`,
          `${config.REP_API}/api/manage-reps/get-manager-list-company`,
          data,
          {
            headers: { "content-type": "application/json" },
          }
        )
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(get(error, "data.payload", constants.ERROR_MESSAGES.DEFAULT));
        });
    });
  }

  function getCompanyWideAverage(obj) {
    return new Promise((resolve, reject) => {
      authdAxios
        .post(
          `${config.REP_API}/api/company-dashboard/company-wide-average`,
          obj,
          {
            headers: {
              "content-type": "application/json",
              Authorization: "",
            },
          }
        )
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(
            get(
              error,
              "response.data.payload",
              constants.ERROR_MESSAGES.DEFAULT
            )
          );
        });
    });
  }

  // POST - https://dev.repora.co:4033/api/company-dashboard/get-company-ratings
  // Payload - {
  //   "companyId": "b6903090-438f-11ec-be3a-dfdc4cf7e9bb"
  // }

  function getCompanyDashboardDetails(obj) {
    return new Promise((resolve, reject) => {
      authdAxios
        .post(
          `${config.REP_API}/api/company-dashboard/get-company-ratings`,
          obj,
          {
            headers: {
              "content-type": "application/json",
              Authorization: "",
            },
          }
        )
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(
            get(
              error,
              "response.data.payload",
              constants.ERROR_MESSAGES.DEFAULT
            )
          );
        });
    });
  }

  function getCompanyTopList(companyId) {
    // https://dev.repora.co:4033/api/company-dashboard/get-company-rep-details-list
    return new Promise((resolve, reject) => {
      authdAxios
        .post(
          `${config.REP_API}/api/company-dashboard/get-company-rep-details-list`,
          companyId,
          {
            timeout: config.API_TIMEOUT_DURATION,
            headers: {
              "content-type": "application/json",
            },
          }
        )
        .then((response) => {
          // debugger;
          // response.data.payload.repViewList = response.data.payload;
          resolve(response.data);
        })
        .catch((error) => {
          reject(
            get(
              error,
              "response.data.payload",
              constants.ERROR_MESSAGES.DEFAULT
            )
          );
        });
    });
  }

  function getCompanyTopBottomList(companyId) {
    // https://dev.repora.co:4033/api/company-dashboard/get-company-rep-details-list
    return new Promise((resolve, reject) => {
      authdAxios
        .post(
          `${config.REP_API}/api/company-dashboard/get-company-dashboard-top-reps`,
          companyId,
          {
            timeout: config.API_TIMEOUT_DURATION,
            headers: {
              "content-type": "application/json",
            },
          }
        )
        .then((response) => {
          // debugger;
          // response.data.payload.repViewList = response.data.payload;
          resolve(response.data);
        })
        .catch((error) => {
          reject(
            get(
              error,
              "response.data.payload",
              constants.ERROR_MESSAGES.DEFAULT
            )
          );
        });
    });
  }

  function getAllPrograms(companyId) {
    return new Promise((resolve, reject) => {
      authdAxios
        .post(
          `${config.REP_API}/api/company-feedback/get-program-list`,
          companyId,
          {
            timeout: config.API_TIMEOUT_DURATION,
            headers: {
              "content-type": "application/json",
            },
          }
        )
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(
            get(
              error,
              "response.data.payload",
              constants.ERROR_MESSAGES.DEFAULT
            )
          );
        });
    });
  }

  function getProgram_TeamaverageRatings(
    companyId,
    verifiedCustomerOnly,
    anonymousHidden,
    compared,
    categoryID,
    category,
    tagId,
    role,
    emailId,
    myRole
  ) {
    let obj;
    if (category === "PROGRAM") {
      obj = {
        companyId,
        verifiedCustomerOnly,
        anonymousHidden,
        compared,
        repType: role,
        companyFeedbackCategory: category,
        categoryId: categoryID,
        tagId,
      };
    } else if (category === "TEAM") {
      obj = {
        companyId,
        verifiedCustomerOnly,
        anonymousHidden,
        compared,
        repType: role,
        companyFeedbackCategory: category,
        categoryId: categoryID,
      };
    }

    if (myRole === "REGULAR") {
      Object.assign(obj, { companyUserEmail: emailId });
    }
    return new Promise((resolve, reject) => {
      authdAxios
        .post(`${config.REP_API}/api/company-feedback/averages`, obj, {
          timeout: config.API_TIMEOUT_DURATION,
          headers: {
            "content-type": "application/json",
          },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(
            get(
              error,
              "response.data.payload",
              constants.ERROR_MESSAGES.DEFAULT
            )
          );
        });
    });
  }

  function getCatogeryRepTrends(
    companyId,
    beginDateInMillis,
    endDateInMillis,
    verifiedCustomerOnly,
    anonymousHidden,
    categoryID,
    category,
    tagId,
    role,
    emailId,
    myRole
  ) {
    let obj;
    if (category === "PROGRAM") {
      obj = {
        companyId,
        beginDateInMillis,
        endDateInMillis,
        verifiedCustomerOnly,
        anonymousHidden,
        companyFeedbackCategory: category,
        categoryId: categoryID,
        tagId,
      };
    } else if (category === "TEAM") {
      obj = {
        companyId,
        beginDateInMillis,
        endDateInMillis,
        verifiedCustomerOnly,
        anonymousHidden,
        companyFeedbackCategory: category,
        categoryId: categoryID,
      };
    }
    //verifiedcostmeronly not needed
    if (myRole === "REGULAR") {
      Object.assign(obj, { companyUserEmail: emailId });
    }
    return new Promise((resolve, reject) => {
      authdAxios
        .post(`${config.REP_API}/api/company-feedback/graphics`, obj, {
          timeout: config.API_TIMEOUT_DURATION,
          headers: {
            "content-type": "application/json",
          },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(
            get(
              error,
              "response.data.payload",
              constants.ERROR_MESSAGES.DEFAULT
            )
          );
        });
    });
  }

  function getProgramCompanyMaps(
    companyId,
    beginDateInMillis,
    endDateInMillis,
    verifiedCustomerOnly,
    anonymousHidden,
    category,
    globalcategory,
    tagId,
    filter,
    value,
    threshold,
    repType,
    categoryID,
    emailId,
    myRole
  ) {
    let obj = {
      companyId,
      beginDateInMillis,
      endDateInMillis,
      verifiedCustomerOnly,
      anonymousHidden,
      category,
      filter,
      value,
      threshold,
      repType,
      categoryId: categoryID,
      companyFeedbackCategory: globalcategory,
      tagId,
    };
    if (myRole === "REGULAR") {
      Object.assign(obj, { companyUserEmail: emailId });
    }
    return new Promise((resolve, reject) => {
      authdAxios
        .post(`${config.REP_API}/api/company-feedback/maps`, obj, {
          timeout: config.API_TIMEOUT_DURATION,
          headers: {
            "content-type": "application/json",
          },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(
            get(
              error,
              "response.data.payload",
              constants.ERROR_MESSAGES.DEFAULT
            )
          );
        });
    });
  }

  function getCatogeryHotspotReport(
    companyId,
    beginDateInMillis,
    endDateInMillis,
    verifiedCustomerOnly,
    anonymousHidden,
    categoryId,
    category,
    tagId,
    role,
    emailId,
    myRole,
    seeAllData
  ) {
    let obj;
    if (category === "PROGRAM") {
      obj = {
        companyId,
        beginDateInMillis,
        endDateInMillis,
        anonymousHidden,
        companyFeedbackCategory: category,
        categoryId,
        tagId,
      };
    } else if (category === "TEAM") {
      obj = {
        companyId,
        beginDateInMillis,
        endDateInMillis,
        anonymousHidden,
        companyFeedbackCategory: category,
        categoryId,
      };
    }
    if (myRole === "REGULAR" && !seeAllData) {
      Object.assign(obj, { companyUserEmail: emailId });
    }
    return new Promise((resolve, reject) => {
      authdAxios
        .post(`${config.REP_API}/api/company-feedback/hotspot`, obj, {
          timeout: config.API_TIMEOUT_DURATION,
          headers: {
            "content-type": "application/json",
          },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(
            get(
              error,
              "response.data.payload",
              constants.ERROR_MESSAGES.DEFAULT
            )
          );
        });
    });
  }

  function getProgramData(Id) {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${config.REP_API}/api/link/get-program`,
          { id: Id },
          {
            timeout: config.API_TIMEOUT_DURATION,
            headers: {
              "content-type": "application/json",
            },
          }
        )
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          debugger;
          reject(
            get(
              error,
              "response.data.payload",
              constants.ERROR_MESSAGES.DEFAULT
            )
          );
        });
    });
  }

  function ratingPagecompanyLogo(url) {
    return new Promise((resolve, reject) => {
      axios
        .get(
          `${config.COMPANY_API}/api/link/get-Company-logo?imageUrl=${url}`,
          { responseType: "arraybuffer" }
        )
        .then((response) => {
          resolve(response.data);
          const url = window.URL.createObjectURL(new Blob([response.data]));
          resolve(url);
        })
        .catch((error) => {
          reject(
            get(
              error,
              "response.data.payload",
              constants.ERROR_MESSAGES.DEFAULT
            )
          );
        });
    });
  }

  function submitRating(obj) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${config.REP_API}/api/link/add-company-feedback`, obj, {
          timeout: config.API_TIMEOUT_DURATION,
          headers: {
            "content-type": "application/json",
          },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(
            get(
              error,
              "response.data.payload",
              constants.ERROR_MESSAGES.DEFAULT
            )
          );
        });
    });
  }

  function getCompanyProgramList(
    companyId,
    beginDateInMillis,
    endDateInMillis,
    anonymousHidden,
    companyFeedbackCategory
  ) {
    let obj = {
      companyId,
      beginDateInMillis,
      endDateInMillis,
      anonymousHidden,
      companyFeedbackCategory,
    };
    let url = `${config.COMPANY_API}/company-feedback/top-feedbacks`;
    url = url.replace("4032", "3033");
    return new Promise((resolve, reject) => {
      authdAxios
        .post(url, obj, {
          headers: {
            "content-type": "application/json",
          },
        })
        .then((response) => {
          // console.log("ress 11", response);
          resolve(response.data);
        })
        .catch((error) => {
          reject(
            get(
              error,
              "response.data.payload",
              constants.ERROR_MESSAGES.DEFAULT
            )
          );
        });
    });
  }

  function getProgramViewdata(Id) {
    return new Promise((resolve, reject) => {
      authdAxios
        .post(
          `${config.REP_API}/api/company-feedback/view-program`,
          { id: Id },
          {
            timeout: config.API_TIMEOUT_DURATION,
            headers: {
              "content-type": "application/json",
            },
          }
        )
        .then((response) => {
          // debugger
          resolve(response.data);
        })
        .catch((error) => {
          debugger;
          reject(
            get(
              error,
              "response.data.payload",
              constants.ERROR_MESSAGES.DEFAULT
            )
          );
        });
    });
  }

  function getTeamViewdata(Id) {
    return new Promise((resolve, reject) => {
      authdAxios
        .post(
          `${config.REP_API}/api/company-feedback/view-team`,
          { id: Id },
          {
            timeout: config.API_TIMEOUT_DURATION,
            headers: {
              "content-type": "application/json",
            },
          }
        )
        .then((response) => {
          // debugger
          resolve(response.data);
        })
        .catch((error) => {
          debugger;
          reject(
            get(
              error,
              "response.data.payload",
              constants.ERROR_MESSAGES.DEFAULT
            )
          );
        });
    });
  }

  function attachTagFeedback(feedbackId, feedbackCategory, tags) {
    let obj = {
      feedbackId,
      tags,
      feedbackCategory,
    };
    return new Promise((resolve, reject) => {
      authdAxios
        .post(
          `${config.CUSTOMER_API}/api/company-feedback/attach-tags-to-feedback`,
          obj,
          {
            headers: {
              "content-type": "application/json",
            },
          }
        )
        .then((response) => resolve(response.data))
        .catch((err) => reject(err));
    });
  }

  function updateDefaultDashboard(obj) {
    return new Promise((resolve, reject) => {
      authdAxios
        .post(`${config.PASSPORT_API}/api/users/update-user-information`, obj, {
          timeout: config.API_TIMEOUT_DURATION,
          headers: {
            "content-type": "application/json",
          },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(
            get(
              error,
              "response.data.payload",
              constants.ERROR_MESSAGES.DEFAULT
            )
          );
        });
    });
  }

  function getTeamData(Id) {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${config.REP_API}/api/link/get-team`,
          { id: Id },
          {
            timeout: config.API_TIMEOUT_DURATION,
            headers: {
              "content-type": "application/json",
            },
          }
        )
        .then((response) => {
          // debugger
          resolve(response.data);
        })
        .catch((error) => {
          debugger;
          reject(
            get(
              error,
              "response.data.payload",
              constants.ERROR_MESSAGES.DEFAULT
            )
          );
        });
    });
  }

  function addCatogeryCustomeAttributes(attrData) {
    return new Promise((resolve, reject) => {
      authdAxios
        .post(
          `${config.REP_API}/api/company-feedback/add-custom-cfc-attribute`,
          attrData,
          {
            headers: {
              "content-type": "application/json",
            },
          }
        )
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  function handleGetCompanyUser(obj) {
    return new Promise((resolve, reject) => {
      authdAxios
        .post(`${config.PASSPORT_API}/api/users/get-user`, obj, {
          timeout: config.API_TIMEOUT_DURATION,
          headers: {
            "content-type": "application/json",
          },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(
            get(
              error,
              "response.data.payload",
              constants.ERROR_MESSAGES.DEFAULT
            )
          );
        });
    });
  }

  function getEmailTemplate(id) {
    return new Promise((resolve, reject) => {
      authdAxios
        .post(
          `${config.ADMIN_API}/api/admin/get-company-template`,
          {
            companyId: id,
          },
          {
            timeout: config.API_TIMEOUT_DURATION,
            headers: {
              "content-type": "application/json",
            },
          }
        )
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(
            get(
              error,
              "response.data.payload",
              constants.ERROR_MESSAGES.DEFAULT
            )
          );
        });
    });
  }

  function updateEmailTemplate(obj) {
    return new Promise((resolve, reject) => {
      authdAxios
        .post(`${config.ADMIN_API}/api/admin/add-template`, obj, {
          timeout: config.API_TIMEOUT_DURATION,
          headers: {
            "content-type": "application/json",
          },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(
            get(
              error,
              "response.data.payload",
              constants.ERROR_MESSAGES.DEFAULT
            )
          );
        });
    });
  }

  return {
    getCompanies,
    getRepList,
    getRepAttrList,
    getCompanyInfo,
    updateCompanyInfo,
    getCompanyRepList,
    getPreviousCompanies,
    getMapsData,
    getDashboardCompanyInfo,
    getHotspotReport,
    updateLogo,
    deleteLogo,
    setMinimumRating,
    getCompanyAverageRatings,
    getCompanyRepTrends,
    getCompanyMaps,
    reportAbuse,
    getCompanyPublicInfo,
    getCompanyPublicInfoByInvite,
    getAssignedRoles,

    getUserCompanies,
    addUserCompany,
    updateUserCompany,
    deleteUserCompany,
    addCompany,

    getCompanySuggestions,

    addCustomeAttributes,
    getCompanyCustomeAttr,
    updateCustomeAttributes,
    deleteCustomeAttributes,
    uploadCRMExcel,
    addCRMExcel,
    updateCRMExcel,
    validateCRMData,
    updatevalidateCRMData,
    deleteCRMData,
    getCRMusersCompany,
    getCRMFileData,
    getCRMFileDownload,
    getCompanyWideAverage,
    getCompanyLogo,
    addCatogeryCustomeAttributes,
    handleGetCompanyUser,
    getEmailTemplate,
    updateEmailTemplate,

    //optimized rateRep API'S
    getUserCompaniesSample,
    getCompanyCustomeAttrSample,

    //dashboard2
    getCompanyDashboardDetails,
    getCompanyTopList,
    getCompanyTopBottomList,
    getAllPrograms,
    getProgram_TeamaverageRatings,
    getCatogeryRepTrends,
    getProgramCompanyMaps,
    getCatogeryHotspotReport,
    getProgramData,
    ratingPagecompanyLogo,
    submitRating,
    getCompanyProgramList,
    getProgramViewdata,
    attachTagFeedback,
    updateDefaultDashboard,
    getTeamData,
    getTeamViewdata,
  };
};

export default CompanyService();
