import React, { Component, Suspense } from "react";
import { connect } from "react-redux";
import { Redirect, Route, Switch } from "react-router-dom";
import "./App.css";
import Loader from "./components/Loader/Loader";
import NavigationWrapper from "./components/NavigationWrapper/NavigationWrapper.js";
import PrivateRoutesGenerator from "./components/PrivateRoutes";
import ScrollToTopRouter from "./components/Scroll/ScrollToTopRouter";
import constants from "./constants";

import HomeUpdated from "./scenes/HomeUpdated/Home";

import Admin from "./scenes/Admin/Admin";
import Admin_UserStats from "./scenes/Admin/manageDispute/Admin_UserStats";
import CustomerReviews from "./scenes/Admin/manageDispute/CustomerReviews";
import dispute from "./scenes/Admin/manageDispute/dispute";
import ManageClaims from "./scenes/Admin/manageDispute/ManageClaims";
import ManageCompanyKeys from "./scenes/Admin/manageDispute/ManageCompanyKeys";
import ManagePartnerKeys from "./scenes/Admin/manageDispute/ManagePartnerKeys";
import UnclaimedReps from "./scenes/Admin/manageDispute/unclaimedReps";
import AdminPannel from "./scenes/Admin/pannel/pannel";
import ClaimAccount from "./scenes/ClaimAccount/ClaimAccount";
import ConfirmEmail from "./scenes/ConfirmEmail/ConfirmEmail";
import ContactUs from "./scenes/ContactUs/ContactUs";
import CustomerReferral from "./scenes/CustomerReferral/ReferralForm";
import DeletionInstructions from "./scenes/FacebookData/DeletionInstructions";
import FeaturesCompaniesUpdated from "./scenes/FeaturesCompaniesUpdated/FeaturesCompanies";
import FeaturesCustomerUpdated from "./scenes/FeaturesCustomerUpdated/FeaturesCustomer";
import FeaturesRepUpdated from "./scenes/FeaturesRepUpdated/FeaturesRep";
import Forgot from "./scenes/Forgot/Forgot";
import HowRepscore from "./scenes/HowRepscore/HowRepscore";
import InvitationSucceeded from "./scenes/InvitationSucceeded/InvitationSucceeded";
import Login from "./scenes/Login/Login";
import ServerMaintain from "./scenes/MaintenanceBreak/maintenancePage";
import OurCompany from "./scenes/OurCompany/OurCompany";
import Pricing from "./scenes/Pricing/Pricing";
import PrivacyPolicy from "./scenes/PrivacyPolicy/PrivacyPolicy";
import RateNewRep from "./scenes/RateNewRep/RateNewRep";
import RateProgram from "./scenes/RateProgram/RateProgram";
import RateRep from "./scenes/RateRep/RateRep";
import RateTeam from "./scenes/RateTeam/RateTeam";
import UnVerified from "./scenes/ReporaIframes/UnVerified/unVerified";
import SignUp from "./scenes/SignUp/SignUp";
import TermsService from "./scenes/TermsService/TermsService";
import ViewRepCustomer from "./scenes/ViewRep/ViewRepCustomer";
import CreateCompany from "./scenes/Admin/manageDispute/CreateCompany";
import CreateRoles from "./scenes/Admin/manageDispute/CreateRoles";
import Home from "./scenes/Home/Home.js";

class App extends Component {
  onRouteChange() {
    if (
      window?.location?.pathname &&
      (window.location?.pathname === "/" ||
        window.location?.pathname === "/featurescustomers" ||
        window.location?.pathname === "/featurescompanies" ||
        window.location?.pathname === "/featuresreps" ||
        window.location?.pathname === "/contactus" ||
        window.location?.pathname === "/pricing" ||
        window.location?.pathname === "/privacy" ||
        window.location?.pathname === "/terms" ||
        window.location?.pathname === "/serverdown")
    ) {
    } else {
      window.location.replace(`${window.location.origin}/serverdown`);
    }
  }

  render() {
    const privateRoutes = PrivateRoutesGenerator({
      isAuthenticated: this.props.isAuthenticated,
      isFetching: this.props.isFetching,
      requestedLogout: this.props.requestedLogout,
      userData: this.props.userData || {},
      location: this.props.location,
    });

    const getDynamicHomePage = {
      stagging: Login,
      local: HomeUpdated,
      development: HomeUpdated,
      prod: HomeUpdated,
      test: HomeUpdated,
    };

    const getDynamicFeaturesCompaniesPage = {
      stagging: FeaturesCompaniesUpdated,
      local: FeaturesCompaniesUpdated,
      development: FeaturesCompaniesUpdated,
      prod: FeaturesCompaniesUpdated,
      test: FeaturesCompaniesUpdated,
    };

    const getDynamicFeaturesCustomersPage = {
      stagging: FeaturesCustomerUpdated,
      local: FeaturesCustomerUpdated,
      development: FeaturesCustomerUpdated,
      prod: FeaturesCustomerUpdated,
      test: FeaturesCustomerUpdated,
    };

    const getDynamicFeaturesRepsPage = {
      stagging: FeaturesRepUpdated,
      local: FeaturesRepUpdated,
      development: FeaturesRepUpdated,
      prod: FeaturesRepUpdated,
      test: FeaturesRepUpdated,
    };

    if (this.props.isAuthenticated) {
      const userRole = this.props.userData && this.props.userData.userRole;
      return (
        <NavigationWrapper>
          <ScrollToTopRouter />
          <Suspense fallback={<Loader overlayed size="medium" />}>
            <Switch>
              <Route exact path="/reset-password" component={Forgot} />
              <Route exact path="/claimaccount" component={ClaimAccount} />
              <Route exact path="/ratenewrep" component={RateNewRep} />
              <Route
                exact
                path="/featurescompanies"
                component={
                  getDynamicFeaturesCompaniesPage[
                    process.env.REACT_APP_NODE_ENV
                  ]
                }
              />
              <Route
                exact
                path="/featurescustomers"
                component={
                  getDynamicFeaturesCustomersPage[
                    process.env.REACT_APP_NODE_ENV
                  ]
                }
              />
              <Route
                exact
                path="/featuresreps"
                component={
                  getDynamicFeaturesRepsPage[process.env.REACT_APP_NODE_ENV]
                }
              />
              <Route exact path="/ourcompany" component={OurCompany} />
              <Route exact path="/howrepscore" component={HowRepscore} />
              <Route exact path="/serverdown" component={ServerMaintain} />
              <Route
                exact
                path="/customer_feedback_program"
                component={CustomerReferral}
              />
              <Route
                exact
                path="/admin/createCompany"
                component={CreateCompany}
              />
              <Route exact path="/admin/createRoles" component={CreateRoles} />
              <Route exact path="/privacy" component={PrivacyPolicy} />
              <Route exact path="/terms" component={TermsService} />
              <Route exact path="/contactus" component={ContactUs} />
              <Route exact path="/pricing" component={Pricing} />
              <Route exact path="/auth/confirm" component={ConfirmEmail} />
              <Route
                exact
                path="/facebook-data-deletion-instructions-url/"
                component={DeletionInstructions}
              />
              <Route
                exact
                path="/admin/createCompany"
                component={CreateCompany}
              />
              <Route exact path="/admin/createRoles" component={CreateRoles} />
              <Route exact path="/admin/dispute" component={dispute} />
              <Route
                exact
                path="/admin/userStats"
                component={Admin_UserStats}
              />
              <Route exact path="/admin/pannel" component={AdminPannel} />
              <Route exact path="/admin/reviews" component={CustomerReviews} />
              <Route
                exact
                path="/admin/unclaimedReps"
                component={UnclaimedReps}
              />
              <Route
                exact
                path="/admin/privatekeys"
                component={ManagePartnerKeys}
              />
              <Route
                exact
                path="/admin/compankeys"
                component={ManageCompanyKeys}
              />
              <Route exact path="/admin/claims" component={ManageClaims} />
              {privateRoutes}
              <Route
                exact
                path="/"
                component={getDynamicHomePage[process.env.REACT_APP_NODE_ENV]}
              />
              <Redirect to={`/${constants.ROLES_REDIRECTION[userRole]}`} />{" "}
              {/*TODO: HANDLE 404 PAGE*/}
            </Switch>
          </Suspense>
        </NavigationWrapper>
      );
    }

    return (
      <NavigationWrapper>
        <ScrollToTopRouter />
        <Suspense fallback={<Loader overlayed size="medium" />}>
          {/* <AmplifySignOut /> */}
          <Switch>
            <Route exact path="/login" component={Login} />
            <Route exact index path="/" component={Login} />
            <Route exact path="/repora-myreps-sa" component={Login} />
            <Route exact path="/repora-viewcompany-sa" component={Login} />
            <Route exact path="/admin" component={Admin} />
            <Route exact path="/unVerified" component={UnVerified} />
            {/* <Route exact path="/admin/dashboard" component={dashboard} /> */}
            <Route
              exact
              path="/invitation-succeeded"
              component={InvitationSucceeded}
            />
            <Route exact path="/signup" component={SignUp} />
            <Route exact path="/reset-password" component={Forgot} />
            <Route exact path="/claimaccount" component={ClaimAccount} />
            <Route
              exact
              path="/featurescompanies"
              component={
                getDynamicFeaturesCompaniesPage[process.env.REACT_APP_NODE_ENV]
              }
            />
            <Route
              exact
              path="/featurescustomers"
              component={
                getDynamicFeaturesCustomersPage[process.env.REACT_APP_NODE_ENV]
              }
            />
            <Route
              exact
              path="/featuresreps"
              component={
                getDynamicFeaturesRepsPage[process.env.REACT_APP_NODE_ENV]
              }
            />
            <Route exact path="/ourcompany" component={OurCompany} />
            <Route exact path="/howrepscore" component={HowRepscore} />
            <Route exact path="/serverdown" component={ServerMaintain} />
            <Route
              exact
              path="/customer_feedback_program"
              component={CustomerReferral}
            />
            <Route exact path="/privacy" component={PrivacyPolicy} />
            <Route exact path="/terms" component={TermsService} />
            <Route exact path="/contactus" component={ContactUs} />
            <Route exact path="/pricing" component={Pricing} />
            <Route exact path="/raterep" component={RateRep} />
            <Route exact path="/rateProgram" component={RateProgram} />
            <Route exact path="/rateTeam" component={RateTeam} />
            <Route exact path="/viewrepcustomer" component={ViewRepCustomer} />
            <Route exact path="/auth/confirm" component={ConfirmEmail} />
            <Route
              exact
              path="/facebook-data-deletion-instructions-url/"
              component={DeletionInstructions}
            />
            {privateRoutes}
            <Route
              exact
              path="/"
              component={getDynamicHomePage[process.env.REACT_APP_NODE_ENV]}
              // component={Home}
            />
            <Redirect to="/" /> {/* TODO: HANDLE 404 PAGE */}
          </Switch>
        </Suspense>
      </NavigationWrapper>
    );
  }
}

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  requestedLogout: state.auth.requestedLogout,
  isFetching: state.auth.isFetching,
  userData: state.auth.userData,
  modalContent: state.modal.content,
});

const mapDispatchToProps = (dispatch) => ({
  handleUserRehydrate(info) {
    // dispatch(receiveUserInfo(info));
    // dispatch(getFleet()); // since locations/vehicles/fleet info is used all over the place, let's load the data store once logged in
  },
});

export const Unwrapped = App;
export default connect(mapStateToProps, mapDispatchToProps)(App);
// export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
