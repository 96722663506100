import authdAxios from "../modules/Authentication/utils/authdAxios";
import jwt from "jsonwebtoken";
import _, { isEmpty } from "lodash";

import config from "../config";
import constants from "../constants";
import { get } from "lodash";
import { onThen, onCatch } from "./utils";
import axios from "axios";
import Axios from "axios";

const authService = () => {
  let isApiCallInProgress = false;

  function fetchLogin(email, password, role) {
    return new Promise((resolve, reject) => {
      authdAxios
        .post(
          `${config.PASSPORT_API}/api/auth/sign-in`,
          {
            username: email,
            password: password,
            role: role,
          },
          {
            timeout: config.API_TIMEOUT_DURATION,
            headers: {
              "content-type": "application/json",
            },
          }
        )
        .then((response) => {
          resolve(response.data.payload);
        })
        .catch((error) => {
          reject(get(error, "data.payload", constants.ERROR_MESSAGES.DEFAULT));
        });
    });
  }

  function fetchIframeLogin(obj) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${config.PASSPORT_API}/api/auth/iframe-sign-in`, obj, {
          timeout: config.API_TIMEOUT_DURATION,
          headers: {
            "content-type": "application/json",
          },
        })
        .then((response) => {
          resolve(response.data.payload);
        })
        .catch((error) => {
          reject(get(error, "data.payload", constants.ERROR_MESSAGES.DEFAULT));
        });
    });
  }

  function fetchAdminLogin(email, password, role) {
    return new Promise((resolve, reject) => {
      Axios.post(
        `${config.PASSPORT_API}/api/auth/admin-sign-in`,
        {
          username: email,
          password: password,
          role: role,
        },
        {
          timeout: config.API_TIMEOUT_DURATION,
          headers: {
            "content-type": "application/json",
          },
        }
      )
        .then((response) => {
          resolve(response.data.payload);
        })
        .catch((error) => {
          reject(get(error, "data.payload", constants.ERROR_MESSAGES.DEFAULT));
        });
    });
  }

  function fetchSocialLogin(
    code,
    role,
    provider,
    callback,
    invitationCompanyId
  ) {
    return new Promise((resolve, reject) => {
      authdAxios
        .post(
          `${config.PASSPORT_API}/api/social-media/sign-in`,
          {
            code,
            role,
            provider,
            callback,
            invitedCompanyId: invitationCompanyId,
          },
          {
            timeout: config.API_TIMEOUT_DURATION,
            headers: {
              "content-type": "application/json",
            },
          }
        )
        .then((response) => {
          resolve(response.data.payload);
        })
        .catch((error) => {
          reject(get(error, "data.payload", constants.ERROR_MESSAGES.DEFAULT));
        });
    });
  }

  function decodeToken(loginData) {
    return new Promise((resolve, reject) => {
      try {
        const { accessToken: t } = loginData.authenticationResultType;
        const decodedPayload = jwt.decode(t);

        const now = Date.now();
        const exp = new Date(decodedPayload.exp * 1000); // multiplier is to convert unix timestamp

        if (now < exp) {
          const decodedLoginData = {
            decoded: decodedPayload,
            loginData: loginData,
          };
          resolve(decodedLoginData);
        } else {
          reject(new Error("Sign in token has expired."));
        }
      } catch (error) {
        reject(new Error("There was an error verifying the token."));
      }
    });
  }

  function saveInfo(decodedLoginData) {
    return new Promise((resolve, reject) => {
      try {
        const decodedToken = decodedLoginData.decoded;
        const userData = decodedLoginData.loginData.userResponse;
        const tokenData = decodedLoginData.loginData.authenticationResultType;

        window.localStorage.setItem(
          "user",
          JSON.stringify({
            ...userData,
            ...tokenData,
            expirationDate: decodedToken.exp,
          })
        );

        if (window.location.pathname === constants.REPORA_SA_PATH.COMPANYVIEW) {
          window.localStorage.setItem("iframe", JSON.stringify(true));
        }

        resolve(decodedLoginData);
      } catch (error) {
        reject(error);
      }
    });
  }

  function updateUserInfo(data) {
    if (window.localStorage) {
      const user = JSON.parse(window.localStorage.getItem("user"));

      window.localStorage.setItem(
        "user",
        JSON.stringify({
          ...user,
          ...data,
        })
      );
    }
    return null;
  }

  function getToken() {
    if (window.localStorage) {
      const user = JSON.parse(window.localStorage.getItem("user"));
      return user && user.accessToken;
    }
    return null;
  }

  const getRefrershToken = async () => {
    try {
      if (!isApiCallInProgress) {
        isApiCallInProgress = true;
        const response = await authdAxios.post(
          `${config.PASSPORT_API}/api/auth/token-for-login`,
          [],
          {
            timeout: config.API_TIMEOUT_DURATION,
            headers: {
              "content-type": "application/json",
            },
          }
        );
        if (!isEmpty(response.data) && response.data?.payload) {
          let user = JSON.parse(window.localStorage.getItem("user"));
          const decodedPayload = jwt.decode(response.data?.payload.accessToken);
          const exp = decodedPayload.exp;
          user["idToken"] = response.data?.payload.idToken;
          user["refreshToken"] = response.data?.payload.refreshToken;
          user["accessToken"] = response.data?.payload.accessToken;
          user["expiresIn"] = response.data?.payload.expiresIn;
          user["expirationDate"] = exp;
          window.localStorage.setItem("user", JSON.stringify(user));
          isApiCallInProgress = false;
        }
      }
    } catch (error) {
      console.log(error);
      isApiCallInProgress = false;
    }
  };

  function isLoggedIn() {
    const token = getToken();
    const user = JSON.parse(window.localStorage.getItem("user"));
    const exp = user ? user.expirationDate : null;
    const isIframe = window.localStorage.getItem("iframe");

    if (token && exp) {
      const now = Date.now();
      const expiration = new Date(parseInt(exp, 10) * 1000);
      if (now < expiration) {
        return true;
      } else {
        if (isIframe) {
          isApiCallInProgress = false;
          getRefrershToken();
          // return true
        } else {
          window.localStorage.clear();
          window.localStorage.setItem("user-expired", "true");
          if (window.location.pathname != "/login") {
            window.location.href = "/login";
          }
          return false;
        }
      }
    }

    // We clear the localstorage data because the user is no longer logged in
    // window.localStorage.clear();
    return false;
  }

  function getUserInfo() {
    if (isLoggedIn() && window.localStorage) {
      try {
        const info = JSON.parse(window.localStorage.getItem("user"));
        return info;
      } catch (error) {
        return null;
      }
    }
    return null;
  }

  function logout() {
    const isExpired = window.localStorage.getItem("user-expired");
    try {
      window.localStorage.clear();
      if (isExpired) {
        window.localStorage.setItem("user-expired", "true");
      }
      return true;
    } catch (error) {
      return false;
    }
  }

  function login(email, password, role) {
    return new Promise((resolve, reject) => {
      fetchLogin(email, password, role)
        .then((loginData) => decodeToken(loginData))
        .then((decodedLoginData) => saveInfo(decodedLoginData))
        .then((decodedLoginData) => resolve(decodedLoginData))
        .catch((error) => reject(error));
    });
  }

  function iframeLogin(obj) {
    return new Promise((resolve, reject) => {
      fetchIframeLogin(obj)
        .then((loginData) => decodeToken(loginData))
        .then((decodedLoginData) => saveInfo(decodedLoginData))
        .then((decodedLoginData) => resolve(decodedLoginData))
        .catch((error) => reject(error));
    });
  }

  function adminLogin(email, password, role) {
    return new Promise((resolve, reject) => {
      fetchAdminLogin(email, password, role)
        .then((loginData) => decodeToken(loginData))
        .then((decodedLoginData) => saveInfo(decodedLoginData))
        .then((decodedLoginData) => resolve(decodedLoginData))
        .catch((error) => reject(error));
    });
  }

  function socialLogin(code, role, provider, callback, invitationCompanyId) {
    return new Promise((resolve, reject) => {
      fetchSocialLogin(code, role, provider, callback, invitationCompanyId)
        .then((loginData) => decodeToken(loginData))
        .then((decodedLoginData) => saveInfo(decodedLoginData))
        .then((decodedLoginData) => resolve(decodedLoginData))
        .catch((error) => reject(error));
    });
  }

  function getSocialUrl(providerName, userRole, callback) {
    return new Promise((resolve, reject) => {
      authdAxios
        .post(
          `${config.PASSPORT_API}/api/social-media/get-url`,
          {
            provider: providerName,
            role: userRole,
            callback: callback,
          },
          {
            timeout: config.API_TIMEOUT_DURATION,
            headers: {
              "content-type": "application/json",
            },
          }
        )
        .then(({ data }) => {
          if (data.statusCode !== 200) {
            reject(data.payload || constants.ERROR_MESSAGES.DEFAULT);
          }
          resolve(data.payload);
        })
        .catch((error) => {
          reject(get(error, "data.payload", constants.ERROR_MESSAGES.DEFAULT));
        });
    });
  }

  function getPasswordVerification(email, password) {
    return new Promise((resolve, reject) => {
      authdAxios
        .post(
          `${config.PASSPORT_API}/api/auth/verify-password`,
          {
            username: email,
            password: password,
          },
          {
            headers: {
              "content-type": "application/json",
            },
          }
        )
        .then(({ data }) => {
          if (data.statusCode !== 200) {
            reject(data || constants.ERROR_MESSAGES.DEFAULT);
          }
          resolve(data);
        })
        .catch((error) => {
          const { data } = error.response || { data: {} };
          reject(data.payload || constants.ERROR_MESSAGES.DEFAULT);
        });
    });
  }

  function changePassword(oldPassword, newPassword) {
    return new Promise((resolve, reject) => {
      authdAxios
        .post(
          `${config.PASSPORT_API}/api/auth/change-password`,
          {
            oldPassword,
            newPassword,
          },
          {
            timeout: config.API_TIMEOUT_DURATION,
            headers: {
              "content-type": "application/json",
            },
          }
        )
        .then(({ data }) => {
          if (data.statusCode !== 200) {
            reject(data || constants.ERROR_MESSAGES.DEFAULT);
          }
          resolve(data);
        })
        .catch((error) => {
          reject(get(error, "data.payload", constants.ERROR_MESSAGES.DEFAULT));
        });
    });
  }

  function updateAdminInfo(info) {
    const {
      id,
      firstName,
      lastName,
      email,
      jobTitle,
      picture,
      companyName,
      country,
      phoneNumber,
      zipCode,
      state,
      city,
      secondaryEmail,
    } = info;
    return new Promise((resolve, reject) => {
      authdAxios
        .post(
          `${config.PASSPORT_API}/api/users/update-user-information`,
          {
            id,
            firstName,
            lastName,
            email,
            jobTitle,
            picture,
            companyName,
            country,
            phoneNumber,
            zipCode,
            state,
            city,
            secondaryEmail,
          },
          {
            headers: {
              "content-type": "application/json",
            },
          }
        )
        .then(({ data }) => {
          if (data.statusCode !== 200) {
            reject(data.payload || constants.ERROR_MESSAGES.DEFAULT);
          }
          resolve(data);
        })
        .catch((error) => {
          const { data } = error || { data: {} };
          reject(data || constants.ERROR_MESSAGES.DEFAULT);
        });
    });
  }

  function confirmEmail(username, password, confirmCode, role) {
    return new Promise((resolve, reject) => {
      authdAxios
        .post(
          `${config.PASSPORT_API}/api/auth/confirm-email`,
          {
            username,
            password,
            confirmCode,
            role,
          },
          {
            headers: {
              "content-type": "application/json",
            },
          }
        )
        .then(_.partial(onThen, resolve, reject))
        .catch(_.partial(onCatch, reject));
    });
  }

  function signinAndConfirmAccount({ confirmCode, password, role, username }) {
    return new Promise((resolve, reject) => {
      authdAxios
        .post(
          `${config.PASSPORT_API}/api/auth/sign-in-after-verification`,
          {
            confirmCode: confirmCode,
            password: password,
            role: role,
            username: username,
          },
          {
            headers: {
              "content-type": "application/json",
            },
          }
        )
        .then((response) => decodeToken(response.data.payload))
        .then((decodedLoginData) => saveInfo(decodedLoginData))
        .then((decodedLoginData) => resolve(decodedLoginData))
        .catch((error) => {
          reject(get(error, "data.payload", constants.ERROR_MESSAGES.DEFAULT));
        });
    });
  }

  function resendAndConfirmAccount({ username, role, password, social }) {
    let url = `${config.PASSPORT_API}/api/auth/resend-verify`;
    let obj = {
      email: username,
      password: password,
      socialMedia: social,
    };
    if (role === "REGULAR") {
      obj = {
        email: username,
        password: password,
        socialMedia: social,
        userRole: "REGULAR",
      };
    }
    return new Promise((resolve, reject) => {
      axios
        .post(url, obj, {
          headers: {
            "content-type": "application/json",
          },
        })
        .then((response) => {
          if (response.data.payload) {
            resolve(response);
          }
        })
        .catch((error) => {
          reject(get(error, "data.payload", constants.ERROR_MESSAGES.DEFAULT));
        });
    });
  }

  return {
    login,
    adminLogin,
    socialLogin,
    logout,
    isLoggedIn,
    getToken,
    getUserInfo,
    getSocialUrl,
    getPasswordVerification,
    updateUserInfo,
    changePassword,
    updateAdminInfo,
    confirmEmail,
    signinAndConfirmAccount,
    resendAndConfirmAccount,
    iframeLogin,
  };
};

export default authService();
